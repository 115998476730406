import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const LegalInvestorStatusAPI = (baseURL: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseURL,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const getLegalStatus = (contractId: number) => {
        const serviceUrl = `/bookbuilding-api/LegalStatus/GetLegalStatus`;
        return api.get(serviceUrl,  { params: { contractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };
    const saveLegalStatus = (data: any) => {
        const serviceUrl = `/bookbuilding-api/LegalStatus/SaveLegalStatus`;
        return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const updateLegalStatus = (data: any) => {
        const serviceUrl = `/bookbuilding-api/LegalStatus/UpdateLegalStatus`;
        return api.put(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
		getLegalStatus,
        saveLegalStatus,
        updateLegalStatus,
    };
};