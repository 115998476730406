import { Col, Modal, Row, Spin } from 'antd';
import DataGrid, {
  Column,
  Export,
  Grouping,
  Pager,
  Sorting,
  HeaderFilter,
  ColumnFixing,
  FilterRow,
  Paging,
  Lookup,
  Editing,
} from 'devextreme-react/data-grid';

import { ContractAPI } from '../../../Api/ContractAPI';
import { SignaturitAPI } from '../../../Api/SignaturitAPI';

import { useTableInRevision } from '../../../Hooks/useTableInRevision';

import { ITableInRevision } from './ITableInRevision';
import { languages } from '../../../Models/languages';
import { LoadingOutlined } from '@ant-design/icons';
import { InvestorAPI } from '../../../Api/InvestorAPI';

const TableInRevision = ({ baseURL, baseURLExternal, translations, getAuthToken }: ITableInRevision) => {
	const contractAPI = ContractAPI(baseURL, baseURLExternal, getAuthToken);
	const signaturitAPI = SignaturitAPI(baseURL, getAuthToken);
	const investorAPI = InvestorAPI(baseURL, baseURLExternal, getAuthToken);

	const {
		loading,
		AmountFormat,
		contractListData,
		cellRenderSendIcon,
		getFilteredSharesTypes,
		updateContract,
		onRowValidating,
		cellRenderDate,
		cellRenderCancelIcon,
		cellRenderEditIcon,
		signatureProviderList,
		onCellPrepared,
		cellRenderSendDate
	} = useTableInRevision({
		contractAPI,
		signaturitAPI,
		translations,
		investorAPI
	});

	return (
		<Row className="table-in-revision" justify="center">
			{contractListData ?
				<Col span={24}>
						<Spin
							spinning={loading}
							indicator={
							<LoadingOutlined style={{ fontSize: 34, color: "black" }} spin />
							}
						>
						<DataGrid
							id="table-in-revision-grid"
							showBorders={true}
							height={'75vh'}
							remoteOperations={true}
							allowColumnResizing={true}
							columnResizingMode={"widget"}
							columnMinWidth={80}
							rowAlternationEnabled={true}
							showRowLines={true}
							dataSource={contractListData}
							onRowUpdated={updateContract}
							hoverStateEnabled={true}
							dateSerializationFormat="dd/MM/yyyy"
							onRowValidating={(e: any) => onRowValidating(e)}
							onCellPrepared={onCellPrepared}
						>
							<HeaderFilter visible={true} />
							<Export enabled={true} />
							<Sorting mode="multiple" />
							<ColumnFixing enabled={true} />
							<FilterRow visible={true} />
							<Grouping contextMenuEnabled={true} expandMode="rowClick" />
							<Grouping autoExpandAll={false} />
							<Paging defaultPageSize={100} />
							
							<Pager
								showPageSizeSelector={true}
								allowedPageSizes={[20, 50, 100]}
								showNavigationButtons={true}
								visible={true}
								showInfo={true}
							/>
							<Editing
								mode="cell"
								useIcons={true}
								allowUpdating={true}
								allowDeleting={false}
							/>
							<Column dataField="id" caption="Contract ID" width={15} allowEditing={false}/>
							<Column dataField="investorName" caption={translations[languages.nombre]} allowEditing={false} alignment='center'/>
							<Column dataField="templateName" caption={translations[languages.template]} alignment='center' width={120}/>
							<Column dataField="commitment" caption={translations[languages.compromiso]} alignment='right' format={AmountFormat} allowEditing={false}/>
							<Column dataField="state" caption={translations[languages.estado]} allowEditing={false} alignment='center'/>
							<Column dataField="created_At" caption={translations[languages.fecha]} width={100} dataType="date" format="dd/MM/yyyy" allowEditing={false} alignment='center'/>
							<Column dataField="bankName" caption="Colocador" allowEditing={false} alignment='center'/>
							<Column dataField="idShareType" caption="Shares Type" allowSearch={false} allowFiltering={false} alignment='center'>
								<Lookup dataSource={(data: any) => data ? getFilteredSharesTypes(data.data) : getFilteredSharesTypes(contractListData[0])} displayExpr="sharesType" valueExpr="idSharesType"/>
							</Column>
							<Column dataField="signatureProvider" caption="Signature Provider" allowSearch={false} allowFiltering={false} alignment='center'>
								<Lookup dataSource={signatureProviderList} displayExpr="id" valueExpr="signatureProvider" />
							</Column>
							<Column dataField="signaturitId" caption="Signaturit Id" allowEditing={true} alignment='center' />
							<Column dataField="signatureDate" caption="Signature Date" allowEditing={true}alignment='center' cellRender={(e: any) => cellRenderDate(e.data.signatureDate)}/>
							<Column dataField="dispatchNotificationDate" caption="Send Date" alignment='center' allowEditing={false}  cellRender={(e: any) => cellRenderSendDate(e.data.dispatchNotificationDate, e.data.idContractState)}/>
							<Column caption="Cancel/Delete" cellRender={cellRenderCancelIcon} width={20} alignment='center' allowEditing={false} />
							<Column caption="Edit" cellRender={cellRenderEditIcon} width={20} alignment='center' />
							<Column caption="Send" cellRender={cellRenderSendIcon} width={150} alignment='center'/>
						</DataGrid>
					</Spin>
{/* 					<Modal
						open={true}
					>
						<p>Are you sure you want to send the contract with the selected shares?</p>
					</Modal> */}
				</Col> 
				: null
			}
		</Row>
	);
};

export default TableInRevision;
