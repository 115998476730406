import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const BankAPI = (baseURL: string |undefined, baseURLExternal: string |undefined, getAuthToken: () => string) => {
    const apiConfig = {
        baseURL,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    };
    const externalAPI = AxiosAPI({ ...apiConfig, baseURL: baseURLExternal });
    const bookbuildingAPI = AxiosAPI({ ...apiConfig });

    const get = () => {
        const serviceUrl = `/Bank/GetAllBanks`;
        return externalAPI.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const getInstitutions = () => {
        const serviceUrl = `/bookbuilding-api/Institution/GetInstitutionValues`;
        return bookbuildingAPI.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
        get,
        getInstitutions
    };
};