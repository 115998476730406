import { Input, Radio } from 'antd';

import FormLayout from '../../FormLayout/FormLayout';

import { FatcaAPI } from '../../../Api/FatcaAPI';

import { useFatca } from '../../../Hooks/useFatca';

import { IFatca } from './IFatca';

import './Fatca.scss';

const Fatca = ({
	baseURL,
	translations,
	getAuthToken,
	unlockNextStep,
}: IFatca) => {

	const fatcaAPI = FatcaAPI(baseURL, getAuthToken);
	const {
		loading,
		getInfo,
		fatcaData,
		radioStyle,
		error,
		handleChangeData,
		saveData,
	} = useFatca({ translations, fatcaAPI, unlockNextStep });

	return (
		<FormLayout
			translations={translations}
			loading={loading}
			title={translations.clasificacionFatca}
			subTitle={<div className="fatca">{translations.encabezadoFatca}<div className="tooltip" onClick={getInfo}>1</div></div>}
			onClick={saveData}
			nextStep={unlockNextStep}
			enableNextStep={fatcaData.id > 0 ? true : false}
		>
			<div className="fatca">
				<Radio.Group
					name="option"
					value={fatcaData.option}
					onChange={handleChangeData}
				>
					<Radio className="fatca-radio" value= {1}> 
						{translations.fatca1}<div className="tooltip" data-key="1" onClick={getInfo}>2</div>
					</Radio>
					<Radio className="fatca-radio" value= {2}> 
						{translations.fatca2}<div className="tooltip" data-key="2" onClick={getInfo}>3</div>
					</Radio>
					<Radio className="fatca-radio"  value= {3}> 
						{translations.fatca3}<div className="tooltip" data-key="3" onClick={getInfo}>4</div>
					</Radio>
					<Radio className="fatca-radio"  value= {4}> 
						{translations.fatca4}<div className="tooltip" data-key="4" onClick={getInfo}>5</div>
					</Radio>
					<Radio className="fatca-radio"  value= {5}> 
						{translations.fatca5}<div className="tooltip" data-key="5" onClick={getInfo}>6</div>
					</Radio>
					<Radio className="fatca-radio"  value= {6}> 
						{translations.fatca6}<div className="tooltip" data-key="6" onClick={getInfo}>7</div>
					</Radio>
					<Radio className="fatca-radio"  value= {7}> 
						{translations.fatca7}<div className="tooltip" data-key="7" onClick={getInfo}>8</div>
					</Radio>
					<Radio className="fatca-radio"  value= {8}> 
						{translations.fatca8}<div className="tooltip" data-key="8" onClick={getInfo}>9</div>
					</Radio>
					<Radio className="fatca-radio" value= {9}> 
						{translations.otro}
					</Radio>
					<div>{translations.fatca9}</div>
					<Input
						name='otherText'
						placeholder={translations.fatca9}
						value={fatcaData.otherText}
						onChange={handleChangeData}
						disabled={fatcaData.option !== 9}
						style={radioStyle}
					/>
				</Radio.Group>
				<span
					className={`error ${
						error ? "show" : null
					}`}
				>
					{translations.messageInvestors}
				</span>
			</div>
		</FormLayout>

	);
};

export default Fatca;
