import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";

import { Utils } from "../Utils";

import { IUsePhysicalInvestorForm } from "./IUsePhysicalInvestorForm";
import { emptyInvestor } from "../Assets/Data/EmptyValues";
import { InvestorDTO } from "../Models/InvestorDTO";

export const useLegalInvestorForm = ({
	translations,
	investorAPI,
	unlockNextStep,
	contractAPI
}: IUsePhysicalInvestorForm) => {
	const [searchParams, _] = useSearchParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [noContent, setNoContent] = useState<boolean>(false);
	const [activityInList, setActivityInList] = useState<boolean>(true)
	const [sourceInList, setSourceInList] = useState<boolean>(true)
	const [nationalitiesList, setNationalitiesList] = useState<any[]>([])
	const [contractLanguage, setContractLanguage] = useState<string>('es');

	const [investorData, setInvestorData] = useState<InvestorDTO>(emptyInvestor);;

	const [errors, setErrors] = useState<any>({
		name: false,
		legalForm: false,
		legalFormFormat: false,
		entityType: false,
		constitutionDate: false,
		constitutionPlace: false,
		jurisdiction: false,
		registeredOffice: false,
		contactPerson: false,
		emailContact: false,
		phone: false,
		commercialRegister: false,
		nationality: false,
		cif: false,
		countryTaxResidence: false,
		operationCountries: false,
		bankDeposits: false,
		bankAddress: false,
		iban: false,
		biC_SWIFT: false,
		regulationOrganism: false,
		countryRegulator: false,
		webRegulator: false,
		regulatoryRecord: false,
		marketName: false,
		marketCountry: false,
		marketWeb: false,
		marketRegistry: false,
		situation: false,
		sector: false,
		incomes: false,
		source: false
	});

	useEffect(() => {
		if (searchParams.has('ContractId')) {
			configureInitialData(Number(searchParams.get('ContractId')));
			getNationalities();
		}
	}, [translations]);

	const configureInitialData = (ContractId: number) => {
		setLoading(true);
		investorAPI.getInvestors(ContractId).then(res => {
			if(res.success){
				if(res.data.length > 0 ) setInvestorData(res.data[0]);
			} else {
				setNoContent(true);
			}
			setLoading(false);
		})
		.catch(err => {
			console.log(err);
		});
		contractAPI.getContract(ContractId).then(res => {
			if(res.success){
				if(res.data?.idLanguage){
					setContractLanguage(res.data.idLanguage === 1 ? 'es' : 'en');
				}
			}
		})
	};

	const getNationalities = () => {
		investorAPI.getCountries().then(res => {
			if (res !== "") {
				setNationalitiesList(res);
			}
		})
		.catch(err => {
			console.log(err);
		});
	};

	const handleChangeData = (e: any) => {
		const { name, value } = e.target;
		setErrors((prevState: any) => ({
			...prevState,
			[name]: false
		}));
		setInvestorData((prevState: any) => ({
			...prevState,
			[name]: value
		}));
	};

	const handleSelectData = (field: string) => (value: string) => {
		field === 'sector' && setActivityInList(value !== 'Otro');
		field === 'source' && setSourceInList(value !== 'Otro');
		setErrors((prevState: any) => ({
			...prevState,
			[field]: false
		}));
		setInvestorData((prevState: InvestorDTO) => ({
			...prevState,
			[field]: value
		}));
	};

	const saveData = () => {
		let obj = {...investorData}
		setLoading(true);
		if (validateData(true)) {
			obj.sector = obj.sector === 'Otro' ? obj.otherSector : obj.sector;
			obj.source = obj.source === 'Otro' ? obj.otherSource : obj.source;
			investorAPI.updateInvestors([obj]).then((res: any) => {
				if(res.success){
					message.success(translations.datosGuardados);
					unlockNextStep();
				}else{
					message.error(translations.messageError);
				}
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	const validateData = (modifyErrors: boolean) => {
		const legalFormRegex = /^[A-Za-z0-9À-ÖØ-öø-ÿ'-.]*(?:\s[A-Za-z0-9À-ÖØ-öø-ÿ'-.]*)*$/;
		let isOK = true;
		if (!investorData.name) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, name: true }));
			isOK = false;
		}
		if (!investorData.legalForm) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, legalForm: true }));
			isOK = false;
		} else if (!legalFormRegex.test(investorData.legalForm)) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, legalFormFormat: true }));
			isOK = false;
		}

		if (!investorData.entityType) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, entityType: true }));
			isOK = false;
		}
		if (!investorData.constitutionDate) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, constitutionDate: true }));
			isOK = false;
		}
		if (!investorData.constitutionPlace) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, constitutionPlace: true }));
			isOK = false;
		}
		if (!investorData.jurisdiction) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, jurisdiction: true }));
			isOK = false;
		}
		if (!investorData.registeredOffice) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, registeredOffice: true }));
			isOK = false;
		}
		if (!investorData.contactPerson) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, contactPerson: true }));
			isOK = false;
		}
		if (!investorData.emailContact || !Utils.validateEmail(investorData.emailContact)) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, emailContact: true }));
			isOK = false;
		}
		if (!investorData.phoneNumber) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, phoneNumber: true }));
			isOK = false;
		}
		if (!investorData.phonePrefix) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, phonePrefix: true }));
			isOK = false;
		}
		if (!investorData.commercialRegister) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, commercialRegister: true }));
			isOK = false;
		}
		if (!investorData.nationality) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, nationality: true }));
			isOK = false;
		}
		if (!investorData.cif) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, cif: true }));
			isOK = false;
		}
		if (!investorData.operationCountries) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, operationCountries: true }));
			isOK = false;
		}
		if (!investorData.bankDeposits) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, bankDeposits: true }));
			isOK = false;
		}
		if (!investorData.bankAddress) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, bankAddress: true }));
			isOK = false;
		}
		if (!investorData.iban) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, iban: true }));
			isOK = false;
		}
		if (!investorData.biC_SWIFT) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, biC_SWIFT: true }));
			isOK = false;
		}
		if (investorData.organism && !investorData.regulationOrganism) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, regulationOrganism: true }));
			isOK = false;
		}
		if (investorData.organism && !investorData.countryRegulator) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, countryRegulator: true }));
			isOK = false;
		}
		if (investorData.organism && !investorData.webRegulator) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, webRegulator: true }));
			isOK = false;
		}
		if (investorData.organism && !investorData.regulatoryRecord) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, regulatoryRecord: true }));
			isOK = false;
		}
		if (investorData.contributor && !investorData.marketName) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, marketName: true }));
			isOK = false;
		}
		if (investorData.contributor&& !investorData.marketCountry) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, marketCountry: true }));
			isOK = false;
		}
		if (investorData.contributor && !investorData.marketWeb) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, marketWeb: true }));
			isOK = false;
		}
		if (investorData.contributor && !investorData.marketRegistry) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, marketRegistry: true }));
			isOK = false;
		}
		if (!investorData.nationality) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, nationality: true }));
			isOK = false;
		}
		if (!investorData.lei) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, lei: true }));
			isOK = false;
		}
		if ((investorData.sector === 'Otro' && !investorData.otherSector) || !investorData.sector) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, sector: true }));
			isOK = false;
		}
		if (!investorData.incomes) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, incomes: true }));
			isOK = false;
		}
		if ((investorData.source === 'Otro' && !investorData.otherSource) || !investorData.source) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, source: true }));
			isOK = false;
		}
		if (!investorData.lastPatrimony) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, lastPatrimony: true }));
			isOK = false;
		}

		return isOK;
	};

	const handleCheckedData = (e: any) => {
		const { name } = e.target;
		setInvestorData((prevState: any) => ({
			...prevState,
			[name]: e.target.checked
		}));
	};


	return {
		loading,
		noContent,
		errors,
		investorData,
		nationalitiesList,
		activityInList,
		sourceInList,
		handleChangeData,
		handleSelectData,
		handleCheckedData,
		saveData,
		validateData,
		contractLanguage
	};
};

