  export const sectorOptionsLanguages = [
	{ es: 'Construcción', en: 'Construction' },
	{ es: 'Comercio Internacional', en: 'International Trade' },
	{ es: 'Deportista profesional', en: 'Professional Athlete' },
	{ es: 'Anticuario', en: 'Antique Dealer' },
	{ es: 'Comercio de metales preciosos', en: 'Precious Metal Trading' },
	{ es: 'Organismos sin ánimo de lucro', en: 'Non-profit Organizations' },
	{ es: 'Compra-venta moneda extranjera', en: 'Foreign Currency Trading' },
	{ es: 'Empresa de Auditoría', en: 'Auditing Company' },
	{ es: 'Administraciones Públicas', en: 'Public Administrations' },
	{ es: 'Industria tabaquera', en: 'Tobacco Industry' },
	{ es: 'Automóviles', en: 'Automobiles' },
	{ es: 'Joyería', en: 'Jewelry' },
	{ es: 'Venta al por mayor', en: 'Wholesale' },
	{ es: 'Gestión y alquiler de bienes inmuebles', en: 'Real Estate Management and Rental' },
	{ es: 'Comercio electrónico', en: 'E-commerce' },	
	{ es: 'Intermediarios financieros', en: 'Financial Intermediaries' },
	{ es: 'Comercio de arte', en: 'Art Trading' },
	{ es: 'Casinos', en: 'Casinos' },
	{ es: 'Despacho de Abogado', en: 'Law Firm' },
	{ es: 'Notaría', en: 'Notary\'s office' }
  ]

  export const sourceOptionsLanguages = [
	{ es: 'Salario', en: 'Salary' },
	{ es: 'Actividad empresarial', en: 'Business Activity' },
	{ es: 'Venta de bienes', en: 'Sale of Goods' },
	{ es: 'Indemnización', en: 'Compensation' },
	{ es: 'Donación', en: 'Donation' },
	{ es: 'Herencia', en: 'Inheritance' },
	{ es: 'Ahorros', en: 'Savings-Economy' }
  ];

export const amountOptionsLanguages = [
	{ es: 'Hasta 30.000 euros', en: 'Up to 30,000 euros' },
	{ es: 'De 30.000 a 60.000 euros', en: 'From 30,000 to 60,000 euros' },
	{ es: 'De 60.000 a 100.000 euros', en: 'From 60,000 to 100,000 euros' },
	{ es: 'De 100.000 a 300.000 euros', en: 'From 100,000 to 300,000 euros' },
	{ es: 'Más de 300.000', en: 'More than 300,000' }
];

export const incomeOptionsLanguages = [
	{ es: 'Sin ingresos anuales', en: 'No annual income' },	
	...amountOptionsLanguages,
]

export const activityOptionsLanguages = [
	{ es: 'Cuenta propia', en: 'Self-employed' },
	{ es: 'Cuenta ajena', en: 'Employee' },
	{ es: 'Jubilado', en: 'Retired' },
	{ es: 'Desempleado', en: 'Unemployed' },
	{ es: 'Pensionista', en: 'Pensioner' },
	{ es: 'Estudiante', en: 'Student' }
];