import { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { ConfigProvider, Layout } from "antd";

import { PrivateRoute } from '@qualitas/auth';
import Navbar, { NavbarApi, useNavbar, Footer } from "@qualitas/navbar";

import Stepper from '../FormContract/Stepper/Stepper';
import LanguageSelector from "../LanguageSelector/LanguageSelector";

import { BankAPI } from "../../Api/BankAPI";

import { IAppLayout } from "./IAppLayout";
import { Institution } from "../../Models/Institution";
import HomePage from "../HomePage/HomePage";
import { InstitutionsAPI } from "../../Api/InstitutionAPI";

const AppLayout = ({
	baseURL,
	baseURLExternal,
	getAuthToken,
	validateAuthToken,
	language,
	setLanguage,
	logout,
	userId,
	userContactId,
	userEmail,
	userLogo,
	userPrimaryColor,
	userIdInstitution,
	sendResetPasswordEmail,
	translations
}: IAppLayout) => {
	const navigate = useNavigate();
	const [searchParams, _] = useSearchParams();
	
	const [institutions, setInstitutions] = useState<Institution[]>([]);
	const [institutionData, setInstitutionData] = useState<Institution>({
		idInstitution: 0,
		logo: '',
		institutionLong: '',
		institutionValues: []
	});
	
	const api = NavbarApi(baseURLExternal, getAuthToken);
	const { translations: navbarTranslations, ...NavbarProps} = useNavbar({
		api,
		idSystemMaster: 1,
		userData: {
			userId,
			userContactId,
			userEmail,
			userLogo,
			userPrimaryColor
		},
		navigate,
		migratedUrl: process.env['REACT_APP_APP_URL'],
    	notMigratedUrl: process.env['REACT_APP_APP_URL'],
		sendResetPasswordEmail: () => sendResetPasswordEmail(userEmail),
		language
	});

	const [isSuitabilityTestEnabled, setIsSuitabilityTestEnabled] = useState(true);

 	useEffect(() => {
		const banksAPI = BankAPI(baseURL, baseURLExternal, getAuthToken);
		const institutionsAPI = InstitutionsAPI(baseURL, getAuthToken);
		banksAPI.getInstitutions()
			.then((res: any) => {
				if(res.success){
					setInstitutions(res.data);
					let inst = res.data.filter((x: any) => x.idInstitution === userIdInstitution);
					if(inst.length > 0) {
						setInstitutionData(inst[0]);
						if(userIdInstitution !== 536){
							setInstitutions(res.data.filter((x: any) => x.idInstitution === userIdInstitution));
						}
						/* if (searchParams.has('ContractId')) {
							getContractData(Number(searchParams.get('ContractId')));
						} */
					}
				}
			});

		if(userIdInstitution){
			institutionsAPI.getSuitabilityTestEnabled(userIdInstitution)
				.then((res: any) => {
					if(res.success){
						setIsSuitabilityTestEnabled(res.data);
					}
			});
		}

	}, [userIdInstitution]);
 
	ConfigProvider.config({
		theme:{
			primaryColor: '#b3d615',
			infoColor: '#b3d615',
			successColor: '#8fac10'
		}
	});

	return (
		<Layout>
			<Navbar
				{...NavbarProps}
				userName={NavbarProps.menuName ?? ""}
				logout={logout}
				reportIncident={() => console.log("report")}
				isBackoffice={false}
				translations={navbarTranslations}
				extraContent={
					<LanguageSelector
						setLanguage={setLanguage}
						language={language}
					/>
				}
			/>
			<ConfigProvider>
				<Routes>
					<Route path="/" element={<PrivateRoute validateAuthToken={validateAuthToken}/>}>
						<Route path="/" element={
							<HomePage
								baseURL={baseURL}
								baseURLExternal={baseURLExternal}
								translations={translations}
								userEmail={userEmail}
								userId={userId}
								userIdInstitution={userIdInstitution}
								institutions={institutions}
								getAuthToken={getAuthToken}
							/>
						} />
						<Route path="/FormContract" element={
							<Stepper
								baseURL={baseURL}
								baseURLExternal={baseURLExternal}
								translations={translations}
								userIdInstitution={userIdInstitution}
								institutionData={institutionData}
								getAuthToken={getAuthToken}
								userEmail={userEmail}
								isSuitabilityTestEnabled={isSuitabilityTestEnabled}
							/>
						}/>
					</Route>
					<Route path="*" element={<h1>Redirección</h1>}/>
				</Routes>
			</ConfigProvider>

			<Footer language={language}/>
		</Layout>
	);
};

export default AppLayout;
