import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const PhysicalInvestorStatusAPI = (baseURL: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseURL,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const getPhysicalStatus = (contractId: number) => {
        const serviceUrl = `/bookbuilding-api/PhysicalStatus/GetPhysicalStatus`;
        return api.get(serviceUrl,  { params: { contractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };
    const savePhysicalStatus = (data: any) => {
        const serviceUrl = `/bookbuilding-api/PhysicalStatus/SavePhysicalStatus`;
        return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const updatePhysicalStatus = (data: any) => {
        const serviceUrl = `/bookbuilding-api/PhysicalStatus/UpdatePhysicalStatus`;
        return api.put(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
		getPhysicalStatus,
        savePhysicalStatus,
        updatePhysicalStatus,
    };
};