import { useState } from 'react';
import { Steps } from 'antd';

import InvestorData from '../InvestorData/InvestorData';
import Fatca from '../Fatca/Fatca';
import CRS from '../CRS/CRS';
import Beneficiaries from '../Beneficiaries/Beneficiaries';
import TaxHolder from '../TaxHolder/TaxHolder';
import LegalInvestorStatus from '../LegalInvestorStatus/LegalInvestorStatus';
import PhysicalInvestorStatus from '../PhysicalInvestorStatus/PhysicalInvestorStatus';
import LegalInvestorForm from '../LegalInvestorForm/LegalInvestorForm';
import PhysicalInvestorForm from '../PhysicalInvestorForm/PhysicalInvestorForm';
import SuitabilityTest from '../SuitabilityTest/SuitabilityTest';
import CreateSignature from '../../CreateSignature/CreateSignature';
import DistributorData from '../DistributorData/DistributorData';

import { IStepper } from './IStepper';
import { languages } from '../../../Models/languages';

import './Stepper.scss';
import Contacts from '../Contacts/Contacts';
import RepresentativesForm from '../RepresentativesForm/RepresentativesForm';

const { Step } = Steps;

const Stepper = ({
	baseURL,
	baseURLExternal,
	translations,
	userIdInstitution,
	institutionData,
	getAuthToken,
	userEmail,
	isSuitabilityTestEnabled
}: IStepper) => {
	const [cuerrentStep, setCuerrentStep] = useState<number>(0);
	const [selectedPersonType, setSelectedPersonType] = useState<1|2>(1);/* FISICA 1 | JURIDICA 2 */
	const [stepsFilled, setStepsFilled] = useState<number>(0);
	const [contractInstitution, setContractInstitution] = useState<number>(0);

	const onChange = (value: number) => {
		setCuerrentStep((prevState: number) => {
			if (!isSuitabilityTestEnabled && value === 1) return ++value;
			return value;
		});
	};

	const unlockNextStep = () => {
		setStepsFilled((prevState: number) => {
			if (!isSuitabilityTestEnabled && prevState === 0) return prevState + 2;
			return ++prevState;
		});
		setCuerrentStep((prevState: number) => {
			if (!isSuitabilityTestEnabled && prevState === 0) return prevState + 2;
			return ++prevState;
		});
	};

	let blackLogo = '';
	if (userEmail.includes('santander')) {
	  blackLogo = 'https://qualitasfunds.blob.core.windows.net/assets/image-gallery/SantanderBlack.png';
	} else if(userEmail.includes('singular')){
	  blackLogo = 'https://qualitasfunds.blob.core.windows.net/assets/image-gallery/SingularLogoBlack.png';
	} else if(userEmail.includes('creand')){
	  blackLogo = 'https://qualitasfunds.blob.core.windows.net/assets/image-gallery/CreandLogoBlack.png';
	}else{
	  blackLogo = 'https://qualitasfunds.blob.core.windows.net/assets/image-gallery/blackLogo.png';
	}

	return (
		<div className="stepper">
			{userIdInstitution !== 0
				? <>
					{selectedPersonType === 2 ?
					/* Persona Juridica */
						<div className="main-content">
							<div className="stepper-wrapper">
								<div className="stepper-sidebar-logo">
									<img
										src={blackLogo}
										alt="colocador-logo"
									/>
								</div>
								<Steps direction="vertical" current={cuerrentStep} onChange={onChange} >
									<Step title={translations[languages.datosFirmantes]}/>
									<Step title={translations[languages.suitabilityTest]} disabled={stepsFilled <= 0 || !isSuitabilityTestEnabled}/>
									<Step title={translations[languages.datosDistribuidor]} disabled={stepsFilled <= 1}/>
									<Step title={translations[languages.formularioInversor]} disabled={stepsFilled <= 2}/>
									<Step title={translations[languages.representativeData]} disabled={stepsFilled <= 3}/>
									<Step title={translations[languages.contactosInversor]} disabled={stepsFilled <= 4}/>
									<Step title={translations[languages.clasificacionFatca]} disabled={stepsFilled <= 5}/>
									<Step title={translations[languages.clasificacionCRS]} disabled={stepsFilled <= 6}/>
									<Step title={translations[languages.beneficiarios]} disabled={stepsFilled <= 7}/>
									<Step title={translations[languages.titularFiscal]} disabled={stepsFilled <= 8}/>
									<Step title={translations[languages.estatusInversor]} disabled={stepsFilled <= 9}/>
									<Step title={translations[languages.sendContractToApproval]} disabled={stepsFilled <= 10}/>
								</Steps>							
							</div>
								{cuerrentStep === 0
									? <InvestorData
										baseURL={baseURL}
										baseURLExternal={baseURLExternal}
										selectedPersonType={selectedPersonType}
										translations={translations}
										institutionData={institutionData}
										getAuthToken={getAuthToken}
										setSelectedPersonType={setSelectedPersonType}
										unlockNextStep={unlockNextStep}
										setContractInstitution={setContractInstitution}
										userIdInstitution={userIdInstitution}
									/>
									: null
								}
								{cuerrentStep === 1
									? <SuitabilityTest
										baseURL={baseURL}
										baseURLExternal={baseURLExternal}
										translations={translations}
										getAuthToken={getAuthToken}
										unlockNextStep={unlockNextStep}
									/> : null
								}
								{cuerrentStep === 2
									? <DistributorData
										baseURL={baseURL}
										baseURLExternal={baseURLExternal}
										translations={translations}
										getAuthToken={getAuthToken}
										unlockNextStep={unlockNextStep}
									/> : null
								}
								{cuerrentStep === 3
									? <LegalInvestorForm
										baseURL={baseURL}
										baseURLExternal={baseURLExternal}
										translations={translations}
										getAuthToken={getAuthToken}
										unlockNextStep={unlockNextStep}
									/> : null
								}
								{cuerrentStep === 4
									? <RepresentativesForm
										baseURL={baseURL}
										baseURLExternal={baseURLExternal}
										translations={translations}
										getAuthToken={getAuthToken}
										unlockNextStep={unlockNextStep}
									/> : null
								}
								{cuerrentStep === 5
									? <Contacts
										baseURL={baseURL}
										baseURLExternal={baseURLExternal}
										translations={translations}
										getAuthToken={getAuthToken}
										unlockNextStep={unlockNextStep}
									/> : null
								}
								{cuerrentStep === 6
									? <Fatca
										baseURL={baseURL}
										translations={translations}
										getAuthToken={getAuthToken}
										unlockNextStep={unlockNextStep}
									/> : null
								}
								{cuerrentStep === 7
									? <CRS
										baseURL={baseURL}
										translations={translations}
										getAuthToken={getAuthToken}
										unlockNextStep={unlockNextStep}
									/> : null
								}
								{cuerrentStep === 8
									? <Beneficiaries
										baseURL={baseURL}
										baseURLExternal={baseURLExternal}
										translations={translations}
										getAuthToken={getAuthToken}
										unlockNextStep={unlockNextStep}
									/> : null
								}
								{cuerrentStep === 9
									? <TaxHolder
										baseURL={baseURL}
										translations={translations}
										getAuthToken={getAuthToken}
										unlockNextStep={unlockNextStep}
									/> : null
								}
								{cuerrentStep === 10
									? <LegalInvestorStatus
										baseURL={baseURL}
										translations={translations}
										getAuthToken={getAuthToken}
										unlockNextStep={unlockNextStep}
									/> : null
								}
								{cuerrentStep === 11
									? <CreateSignature
										baseURL={baseURL}
										baseURLExternal={baseURLExternal}
										translations={translations}
										getAuthToken={getAuthToken}
									/> : null 
								}
						</div>
						:
						/* Persona Fisica */
						<div className="main-content">
							<div className="stepper-wrapper">
								<div className="stepper-sidebar-logo">
									<img
										src={blackLogo}
										alt="colocador-logo"
									/>
								</div>
								<Steps direction="vertical" current={cuerrentStep} onChange={onChange} >
									<Step title={translations[languages.datosFirmantes]}/>
									<Step title={translations[languages.suitabilityTest]} disabled={stepsFilled <= 0}/>
									<Step title={translations[languages.datosDistribuidor]} disabled={stepsFilled <= 1}/>
									<Step title={translations[languages.formularioInversor]} disabled={stepsFilled <= 2}/>
									<Step title={translations[languages.contactosInversor]} disabled={stepsFilled <= 3}/>
									<Step title={translations[languages.estatusInversor]} disabled={stepsFilled <= 4}/>
									<Step title={translations[languages.sendContractToApproval]} disabled={stepsFilled <= 5}/>
								</Steps>
							</div>
							{cuerrentStep === 0
								? <InvestorData
									baseURL={baseURL}
									baseURLExternal={baseURLExternal}
									selectedPersonType={selectedPersonType}
									translations={translations}
									institutionData={institutionData}
									getAuthToken={getAuthToken}
									setSelectedPersonType={setSelectedPersonType}
									unlockNextStep={unlockNextStep}
									setContractInstitution={setContractInstitution}
									userIdInstitution={userIdInstitution}
								/>
								: null
							}
							{cuerrentStep === 1
								? <SuitabilityTest
									baseURL={baseURL}
									baseURLExternal={baseURLExternal}
									translations={translations}
									getAuthToken={getAuthToken}
									unlockNextStep={unlockNextStep}
								/> : null
							}
							{cuerrentStep === 2
								? <DistributorData
									baseURL={baseURL}
									baseURLExternal={baseURLExternal}
									translations={translations}
									getAuthToken={getAuthToken}
									unlockNextStep={unlockNextStep}
								/> : null
							}
							{cuerrentStep === 3
								? <PhysicalInvestorForm
									baseURL={baseURL}
									baseURLExternal={baseURLExternal}
									translations={translations}
									getAuthToken={getAuthToken}
									unlockNextStep={unlockNextStep}
								/> : null
							}
							{cuerrentStep === 4
								? <Contacts
									baseURL={baseURL}
									translations={translations}
									getAuthToken={getAuthToken}
									unlockNextStep={unlockNextStep}
								/> : null
							}
							{cuerrentStep === 5
								? <PhysicalInvestorStatus
									baseURL={baseURL}
									translations={translations}
									getAuthToken={getAuthToken}
									unlockNextStep={unlockNextStep}
								/> : null
							}
							{cuerrentStep === 6
								? <CreateSignature
									baseURL={baseURL}
									baseURLExternal={baseURLExternal}
									translations={translations}
									getAuthToken={getAuthToken}
								/> : null
							}
						</div>
					}
				</> : null
			}
		</div>
	);
};

export default Stepper;
