import { Beneficiaries } from "../../Models/Beneficiaries";
import { CrsDTO } from "../../Models/CrsAPI";
import { FatcaDTO } from "../../Models/FatcaAPI";
import { InvestorDTO } from "../../Models/InvestorDTO";
import { LegalInvestorStatus, PhysicalInvestorStatus } from "../../Models/InvestorStatus";
import { Representative } from "../../Models/Representative";
import { TaxHolder } from "../../Models/TaxHolders";

export const emptyInvestor: InvestorDTO = {
	id: 0,
	name: "",
	lastName: "",
	birthDate: "",
	birthPlace: "",
	nationality: "",
	mainResidence: "",
	mainResidenceCountry: "",
	postalAddress: "",
	emailContact: "",
	phoneNumber: "",
	phonePrefix: "",
	dni: "",
	nif: "",
	cif: "",
	passport: "",
	residenceCard: "",
	managerName: "",
	constitutionDate: "",
	registeredOffice: "",
	countryTaxResidence: "",
	taxIdentification: "",
	ocupation: "",
	employerName: "",
	prp: "",
	familyPRP: "",
	sourceOfFunds: "",
	bankDeposits: "",
	bankAddress: "",
	biC_SWIFT: "",
	iban: "",
	american: false,
	notNIFReason: 0,
	notNIFDetailReason: "",
	legalForm: "",
	incorporationDate: "",
	constitutionPlace: "",
	jurisdiction: "",
	webPage: "",
	contactPerson: "",
	commercialRegister: "",
	nameActivity: "",
	operationCountries: "",
	originFunds: "",
	organism: false,
	regulationOrganism: "",
	webRegulator: "",
	regulatoryRecord: "",
	contributor: false,
	marketName: "",
	marketCountry: "",
	countryRegulator: "",
	marketWeb: "",
	marketRegistry: "",
	fatca: "",
	texto_Fatca: "",
	crs: "",
	relatedntity: "",
	idContract: 0,
	activeENF: "",
	listedCorporation: "",
	status1: false,
	status2: false,
	status3: false,
	status4: false,
	status5: false,
	status6: "",
	status7: false,
	status8: false,
	status9: false,
	status10: "",
	status11: "",
	status12: "",
	status13: "",
	status14: false,
	status15: false,
	sector: "",
	source: "",
	incomes: "",
	situation: ""
}

export const emptyInvestorStatus: PhysicalInvestorStatus = {
	id: 0,
	idContract: 0,
	status1_1: false,
	status1_2: false,
	status1_3: false,
	status2: 0,
	status3: "",
	status4: false,
	status5: false
}

export const emptyLegalInvestorStatus: LegalInvestorStatus = {
	id: 0,
	idContract: 0,
	status1: 0,
	status1_12_1: false,
	status1_12_2: false,
	status1_12_3: false,
	status2: false,
	status3_1: false,
	status3_2: false,
	status3_3: false,
	status4: 0,
	status5: "",
	status6: false,
	status7: false
}


export const emptyRepresentative: Representative = {
	id: 0,
	idContract: 0,
	name: "",
	lastName: "",
	documentType: "",
	documentNumber: "",
	documentExpiration: "",
	birthDate: "",
	nationality: "",
	administrator: "",
	administratorType: "",
	isSignatory: false,
	email: "",
	prP5: false,
	namePRP51: "",
	positionPRP51: "",
	namePRP52: "",
	positionPRP52: ""	
}

export const emptyRepresentativeErrors = {
	name: false,
	lastName: false,
	documentNumber: false,
	documentExpiration: false,
	birthDate: false,
	nationality: false,
	administrator: false,
	administratorType: false,
	namePRP51: false,
	positionPRP51: false,
	email: false,
	documentFormat: false,
	documentExpirationInvalid: false
}

export const emptyFatca: FatcaDTO= {
	id: 0,
	idContract: 0,
}

export const emptyCrs: CrsDTO ={
	id: 0,
	idContract: 0,
}

export const emptyBeneficiary: Beneficiaries = {
	id: 0,
	idContract: 0,
	name: "",
	lastName: "",
	birthDate: "",
	address: "",
	nationality: "",
	countryTaxResidence: "",
	nif: "",
	documentExpiration: "",
	participationPercentage: 0,
	prp: ""
}

export const emptyBeneficiaryErrors = {
	name: false,
	lastName: false,
	birthDate: false,
	address: false,
	nationality: false,
	countryTaxResidence: false,
	nif: false,
	documentExpiration: false,
	participationPercentage: false,
	prp: false,
	notValidExpirationDate: false,
	notValidDocument: false,
}

export const emptyTaxHolder: TaxHolder = {
	id: 0,
	idContract: 0,
	name: "",
	lastName: "",
	taxResidency: "",
	participationPercentage: ""
}

export const emptyTaxHolderErrors = {
	name: false,
	lastName: false,
	taxResidency: false,
	participationPercentage: false
}