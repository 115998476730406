import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

import { InvestorDTO } from '../Models/InvestorDTO';

export const InvestorAPI = (baseURL: string | undefined, baseURLExternal: string | undefined, getAuthToken: () => string) => {
    const apiConfig = {
        baseURL,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    };
    const externalAPI = AxiosAPI({ ...apiConfig, baseURL: baseURLExternal });
    const bookbuildingAPI = AxiosAPI({ ...apiConfig });

    const getCountries = () => {
        const serviceUrl = `/masters-api/Country/GetCountries`;
        return externalAPI.get(serviceUrl)
			.then((response: AxiosResponse) => response.data)
			.catch((error: any) => error);
	};

    const getInvestors = (ContractId: number) => {
        const serviceUrl = `/bookbuilding-api/Investor`;
        return bookbuildingAPI.get(serviceUrl, { params: { ContractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

	const postInvestors = (investors: InvestorDTO[]) => {
        const serviceUrl = `/bookbuilding-api/Investor`;
        return bookbuildingAPI.post(serviceUrl, investors)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
	}

	const updateInvestors = (investors: InvestorDTO[]) => {
        const serviceUrl = `/bookbuilding-api/Investor`;
        return bookbuildingAPI.put(serviceUrl, investors)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
	}

	const deleteInvestor = (id: number) => {
		const serviceUrl = `/bookbuilding-api/Investor`;
		return bookbuildingAPI.delete(serviceUrl, { params: { id } })
			.then((response: AxiosResponse) => response.data)
			.catch((error: any) => error);
	}

	const updateShareType = (idContract: number, idShareType: number) => {
		const serviceUrl = `/bookbuilding-api/Investor/UpdateShareType?IdContract=${idContract}&IdShareType=${idShareType}`;
        return bookbuildingAPI.put(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };


    return {
        getInvestors,
        getCountries,
		postInvestors,
		updateInvestors,
		deleteInvestor,
		updateShareType
    };
};
