import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const TemplateAPI = (baseURL: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseURL,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const getTemplateList = () => {
        const serviceUrl = `/bookbuilding-api/Templates/GetAllTemplates`;
        return api.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
        getTemplateList
    };
};
