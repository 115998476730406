import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const ContactsAPI = (baseURL: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseURL,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const saveContacts = (data: any) => {
        const serviceUrl = `/bookbuilding-api/Contacts/SaveContacts`;
        return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const updateContacts = (data: any) => {
        const serviceUrl = `/bookbuilding-api/Contacts/UpdateContacts`;
        return api.put(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const deleteContact = (contact: number) => {
        const serviceUrl = `/bookbuilding-api/Contacts/DeleteContact`;
        return api.delete(serviceUrl, {params: { id: contact }})
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };
	
    const getAllContacts = (contractId: number) => {
        const serviceUrl = `/bookbuilding-api/Contacts/GetContacts`;
        return api.get(serviceUrl, { params: { contractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
        saveContacts,
        updateContacts,
        deleteContact,
        getAllContacts,
    };
};