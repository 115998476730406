import { Col, Input, Radio, Row } from 'antd';

import { ICRS } from './ICRS';

import './CRS.scss';
import FormLayout from '../../FormLayout/FormLayout';
import { CrsAPI } from '../../../Api/CrsAPI';
import { useCrs } from '../../../Hooks/useCRS';

const CRS = ({
	baseURL,
	translations,
	getAuthToken,
	unlockNextStep,
}: ICRS) => {
	const crsAPI = CrsAPI(baseURL, getAuthToken);
	const {
		loading,
		crsData,
		error,
		handleChangeData,
		getInfo,
		saveData,
	} = useCrs({
		translations,
		crsAPI,
		unlockNextStep
	});

	return (
		<FormLayout
			translations={translations}
			loading={loading}
			title={translations.clasificacionCRS}
			subTitle={<div className="crs">{translations.encabezadoCRS}<div className="tooltip" onClick={getInfo}>1</div></div>}
			onClick={saveData}
			nextStep={unlockNextStep}
			enableNextStep={crsData.id > 0 ? true : false}
		>
			<Radio.Group
				className="crs-radio-group"
				name="option"
				value={crsData.option}
				onChange={handleChangeData}
			>
				<Radio className="crs-radio" value={1}> {translations.crs1}</Radio><br/>
				<Radio className="crs-radio" value={2}> {translations.crs2}</Radio><br/>
				<Radio className="crs-radio" value={3}> {translations.crs3}</Radio><br/>
				<Radio className="crs-radio" value={4}> {translations.crs4}</Radio><br/>
				<Radio className="crs-radio" value={5}> {translations.crs5}</Radio><br/>
				<Radio className="crs-radio" value={6}> {translations.crs6}</Radio><br/>
				<Radio className="crs-radio" value={7}> {translations.crs7}</Radio><br/>
			</Radio.Group>
			<Row className="crs-text-areas">
				<p>{translations.enfActiva}</p>
				<span>{translations.tituloExplicacionEnf}</span>
				<Col xs={24} md={24} lg={24} >
					<Input.TextArea
						name="activeENF"
						value={crsData.activeENF}
						onChange={handleChangeData}
					/>
				</Col>
			</Row>
			<Row className="crs-text-areas">
				<Col xs={24} md={24} lg={24} >
					<p>{translations.entidadVinculada}</p>
					<Input.TextArea
						name="listedCorporation"
						value={crsData.listedCorporation}
						onChange={(e) => handleChangeData(e)}
					/>
				</Col>
			</Row>
			<span
				className={`error ${
					error ? "show" : null
				}`}
			>
				{translations.messageInvestors}
			</span>
		</FormLayout>
	);
};

export default CRS;
