import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import Login, { AuthAPI, useAuth } from '@qualitas/auth';

import AppLayout from './Components/AppLayout/AppLayout';

import { idiomas } from './Assets/idiomas';

import './App.scss';

const baseURL = process.env['REACT_APP_WebAPI_BaseURL'];
const baseURLExternal = process.env['REACT_APP_WebAPI_BaseURLExternal'];
const App = () => {
	const navigate = useNavigate();
	
	const [language, setLanguage] = useState(navigator.language.split("-")[0]);
	const [translations, setTranslations]: any = useState([]);

	useEffect(() => {
		switch (language) {
            case 'es': setTranslations(idiomas.es);
              break;
            case 'en': setTranslations(idiomas.en);
			  break;
		}
	}, [language]);

	const authAPI = AuthAPI(baseURLExternal);
	const authProps = useAuth({
		api: authAPI,
		navigate,
		redirectUrl: '/',
		systemMasterName: 'Bookbuilding',
		redirectPassResetUrl: process.env['REACT_APP_Pass_Reset'],
		language: language
	});

	return (
		<Routes>
			<Route path="/login" element={
				<Login {...authProps}
					appName="Bookbuilding"
				/>
			}/>
			<Route path="*" element={
				<AppLayout
					getAuthToken={authProps.getAuthToken}
					baseURL={baseURL}
					baseURLExternal={baseURLExternal}
					validateAuthToken={authProps.validateAuthToken}
					language={language}
					setLanguage={setLanguage}
					logout={authProps.logOut}
					userId={authProps.userId}
					userContactId={authProps.userContactId}
					userEmail={authProps.userEmail}
					userLogo={authProps.userLogo}
					userPrimaryColor={authProps.userPrimaryColor}
					userIdInstitution={Number(authProps.userIdInstitution)}
					sendResetPasswordEmail={authProps.sendResetPasswordEmail}
					translations={translations}
				/>
			}/>
		</Routes>
	);
};

export default App;
