import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const FatcaAPI = (baseURL: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseURL,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const saveFatca = (data: any) => {
        const serviceUrl = `/bookbuilding-api/FATCA/SaveFATCA`;
        return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const updateFatca = (data: any) => {
        const serviceUrl = `/bookbuilding-api/FATCA/UpdateFATCA`;
        return api.put(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };
	const getFatca = (contractId: number) => {
        const serviceUrl = `/bookbuilding-api/FATCA/GetFATCA`;
        return api.get(serviceUrl, { params: { contractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
        saveFatca,
        updateFatca,
        getFatca,
    };
};