import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const InstitutionsAPI = (baseURL: string |undefined, getAuthToken: () => string) => {
    const apiConfig = {
        baseURL,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    };
    const bookbuildingAPI = AxiosAPI({ ...apiConfig });

    const getSuitabilityTestEnabled = (idInstitution: number) => {
        const serviceUrl = `/bookbuilding-api/Institution/GetSuitabilityTestEnabled`;
        return bookbuildingAPI.get(serviceUrl, { params: { idInstitution } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    return {
		getSuitabilityTestEnabled    
	};
};