import { Alert, Button, Checkbox, Col, Divider, Input, Radio, Row, Tooltip } from 'antd';
import { SaveOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { InvestorAPI } from '../../../Api/InvestorAPI';
import { usePhysicalInvestorStatus } from '../../../Hooks/usePhysicalInvestorStatus';

import { IPhysicalInvestorStatus } from './IPhysicalInvestorStatus';

import './PhysicalInvestorStatus.scss';
import FormLayout from '../../FormLayout/FormLayout';
import { PhysicalInvestorStatusAPI } from '../../../Api/PhysicalInvestorStatusAPI';

const PhysicalInvestorStatus = ({
	baseURL,
	translations,
	getAuthToken,
	unlockNextStep
}: IPhysicalInvestorStatus) => {

	const physicalInvestorStatusAPI = PhysicalInvestorStatusAPI(baseURL, getAuthToken);
	const {
		loading,
		statusData,
		visiblePoints,
		handleCheckedData,
		handleChangeData,
		saveData
	} = usePhysicalInvestorStatus({
		unlockNextStep,
		physicalInvestorStatusAPI,
		translations,
	});

	return (
		<FormLayout 
			translations={translations}
			loading={loading}
			title={translations.estatusInversor}
			subTitle={translations.encabezadoEstatus}
			onClick={saveData}
			disabledSaveButton={sessionStorage.isEditing === "false"}
			nextStep={unlockNextStep}
			enableNextStep={statusData.id > 0 ? true : false}
		>	
			<div className="physicalInvestorStatus">
				<Row>
					<Col xs={24} md={24} lg={24} className="titles">
						{translations.tituloStatusDosFisica}
					</Col>
				</Row>
				<Row className="titles" style={{ border: '1px solid lightgray' }}>
					<Col xs={2} md={1} lg={1} style={{ background: '#F2F2F2', textAlign: 'right', paddingRight: '7px', borderBottom: '1px solid lightgray', }}>
						<Checkbox
							name="status1_1"
							checked={statusData?.status1_1}
							onChange={handleCheckedData}
						/>
					</Col>
					<Col xs={22} md={23} lg={23} style={{ background: '#F2F2F2', borderBottom: '1px solid lightgray', paddingLeft: '5px' }} >
						{translations.opcionAStatusDos}
					</Col>
					<Col xs={2} md={1} lg={1} style={{ textAlign: 'right', paddingRight: '7px', borderBottom: '1px solid lightgray', }}>
						<Checkbox
							name="status1_2"
							checked={statusData.status1_2}
							onChange={handleCheckedData}
						/>
					</Col>
					<Col xs={22} md={23} lg={23} style={{ borderBottom: '1px solid lightgray', paddingLeft: '5px' }} >
						{translations.opcionBStatusDos}
					</Col>
					<Col xs={2} md={1} lg={1} style={{ background: '#F2F2F2', textAlign: 'right', paddingRight: '7px', borderBottom: '1px solid lightgray', }}>
						<Checkbox
							name="status1_3"
							checked={statusData.status1_3}
							onChange={handleCheckedData}
						/>
					</Col>
					<Col xs={22} md={23} lg={23} style={{ background: '#F2F2F2', borderBottom: '1px solid lightgray', paddingLeft: '5px' }}>
						{translations.opcionCStatusDos}
					</Col>

				</Row>
				{((statusData.status1_1 && statusData.status1_2) 
				 ||(statusData.status1_1 && statusData.status1_3) 
				 ||(statusData.status1_2 && statusData.status1_3) 
				)?
					<Alert message="Aporte documentación que justifique opción/opciones seleccionadas o en su defecto puede clasificarse como inversor minorista" banner />
					: null
				}

				{/* PUNTO 3 */}
				{visiblePoints.point3 ?
					<>
						<Divider />
						<span className="titles" >
							{translations.explicacionStatusDosFisica}<br /><br />
						</span>
						<Row>
							<Col xs={24} md={24} lg={24} className="titles">
								2. {translations.tituloStatusTres}
							</Col>
						</Row>
						<Row className="titles">
							<Radio.Group
								className="radio-group"
								name="status2"
								value={statusData.status2}
								onChange={handleChangeData}
							>
								<Radio className="radio-option" value={1}> {translations.opcionAStatusTres}</Radio>
								<Radio className="radio-option" value={2}> {translations.opcionBStatusTres}</Radio>
								<Radio className="radio-option" value={3}> {translations.opcionCStatusTres}</Radio>
								<Radio className="radio-option" value={4}> {translations.ningunaAnterior}</Radio>
							</Radio.Group>
						</Row>
						<Row>
							<Col xs={24} md={24} lg={24} className="titles">
								{translations.tituloExplicacion}
								<Input.TextArea
									name="status3"
									value={statusData.status3}
									onChange={handleChangeData}
								/>
							</Col>
						</Row>
					</> : null
				}

				{/* PUNTO 4 */}
				{visiblePoints.point4 ?
					<>
						<Divider />
						<Row className="titles">
							<Col xs={1} md={1} lg={1}>
								<Checkbox
									name="status4"
									checked={statusData.status4}
									onChange={handleCheckedData}
								/>
							</Col>
							<Col xs={23} md={23} lg={23} style={{ paddingLeft: '7px' }} >
								<Tooltip placement="topLeft" title={translations.tooltipStatusCuatro}>
									<span> 3. {translations.tituloStatusCuatro}</span>  <InfoCircleOutlined className="tooltip"/>
								</Tooltip>
							</Col>

						</Row>
					</> : null
				}
				<Divider />
				<Row className="titles">
					<Col xs={1} md={1} lg={1}>
						<Checkbox
							name="status5"
							checked={statusData.status5}
							onChange={handleCheckedData}
						/>
					</Col>
					<Col xs={23} md={23} lg={23} style={{ paddingLeft: '7px' }}>
						4. {translations.tituloStatusCinco}
					</Col>
				</Row>
				<Row>
					<Col xs={24} md={24} lg={24} className="titles">
						{translations.parrafoFinal}
					</Col>
				</Row>
			</div>
		</FormLayout>
	);
};

export default PhysicalInvestorStatus;
