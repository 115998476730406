import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

export const SignaturitAPI = (baseURL: string |undefined, getAuthToken: () => string) => {
    const apiConfig = {
        baseURL,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    };
    const bookbuildingAPI = AxiosAPI({ ...apiConfig });

    const createSignature = (idContract: number, providerName: string) => {
        const serviceUrl = `/bookbuilding-api/Signature/CreateSignature?IdContract=${idContract}&providerName=${providerName}`;
        return bookbuildingAPI.post(serviceUrl)
    };

    const sendContract = (idContract: number, providerName: string) => {
        const serviceUrl = `/bookbuilding-api/Signature/SendContract?IdContract=${idContract}&providerName=${providerName}`;
        return bookbuildingAPI.post(serviceUrl)
    };

    const cancelContract = (idContract: string, providerName: string) => {
        const serviceUrl = `/bookbuilding-api/Signature/CancelContract?ContractId=${idContract}&providerName=${providerName}`;
        return bookbuildingAPI.put(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };
    
    return {
        createSignature,
        cancelContract,
		sendContract
    };
};
