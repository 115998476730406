import { useNavigate } from "react-router-dom";
import { Alert, Button, Checkbox, Col, DatePicker, Input, Modal, Radio, RadioChangeEvent, Row, Select, Space, Table } from "antd";
import { SaveOutlined, WarningFilled } from "@ant-design/icons";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import Column from "antd/lib/table/Column";
import moment from "moment";

import { SuitabilityTestAPI } from "../../../Api/SuitabilityTestAPI";
import { useSuitabiltyTest } from "../../../Hooks/useSuitabiltyTest";

import { ISuitabilityTest } from "./ISuitabilityTest";
import { Record8 } from "../../../Models/SuitabilityTest";

import './SuitabilityTest.scss';
import FormLayout from "../../FormLayout/FormLayout";
import { InvestorAPI } from "../../../Api/InvestorAPI";

const {Option} = Select;

const SuitabilityTest = ({
	baseURL,
	baseURLExternal,
	translations,
	getAuthToken,
	unlockNextStep
}: ISuitabilityTest) => {
	const navigate = useNavigate();
	
	const suitabilityTestAPI = SuitabilityTestAPI(baseURL, getAuthToken);
	const investorAPI = InvestorAPI(baseURL, baseURLExternal, getAuthToken);	 
	const {
		isSuitabilityDone,
		errors,
		investorInfo,
		testResponses,
		isOpenConfirmModal,
		onQuestionAnswered,
		saveTest,
		onCancelContinue,
		toNextStep,
	} = useSuitabiltyTest({
		suitabilityTestAPI,
		unlockNextStep,
		navigate,
		investorAPI,
		translations
	});

	return (
		<Row className="suitability-test">
			<FormLayout 
				translations={translations}
				loading={false}
				title={translations.suitabilityTest}
				subTitle={translations.suitability_text1}
				onClick={saveTest}
				disabledSaveButton={isSuitabilityDone}
				nextStep={unlockNextStep}
				enableNextStep={/* isSuitabilityDone */ true}
			>
				{ isSuitabilityDone &&
					<Col span={10}>
						<Alert message="Test completado correctamente" type="success" showIcon />
					</Col>
				}
				<Col span={24}>
					<Row gutter={[16, 16]} className="info">
						<strong>{translations.suitability_title1}</strong>
						<ol>
							<li>{translations.suitability_p1}</li>
							<li>{translations.suitability_p2}</li>
							<li>{translations.suitability_p3}</li>
							<li>{translations.suitability_p4}</li>
						</ol>
					</Row>
					<Row gutter={[16, 16]} className="test">
						<Col span="6" className="titles">
							<label className="required"> {translations.nombre}</label>
							<Input
								type="text"
								className="input-field"
								name="name"
								value={investorInfo.name!}
								disabled
								status={errors.name ? 'error' : ''}
							/>
						</Col>
						<Col span="6" className="titles">
							<label className="required"> {translations.apellidosFirmante}</label>
							<Input
								type="text"
								className="input-field"
								name="surname"
								value={investorInfo.surname!}
								disabled
								status={errors.surname ? 'error' : ''}
							/>
						</Col>
						<Col span="6" className="titles">
							<label className="required"> {translations.fechaNacimiento}</label>
							<DatePicker
								className="input-field"
								name="birthDate"
								value={investorInfo.birthDate ? moment(investorInfo.birthDate) : undefined}
								format="DD/MM/YYYY"
								disabled
								status={errors.birthDate ? 'error' : ''}
							/>
						</Col>
						<Col span="6" className="titles">
							<label className="required"> {translations.documentNumber}</label>
							<Input
								type="text"
								className="input-field"
								name="dni"
								value={investorInfo.dni}
								disabled
								status={errors.dni ? 'error' : ''}
							/>
						</Col>
						<Col span="24" className="titles">
							<strong>{translations.suitability_q1}</strong>
							<span className={`error ${errors.question1 ? 'show' : null}`}>{translations.completeCampo}</span>
							<Checkbox 
								disabled={isSuitabilityDone}
								onChange={(e:any) => onQuestionAnswered('trainingMaster')(e.target.checked)}
								checked={testResponses.trainingMaster ?? false}>{translations.suitability_q1_01}
							</Checkbox> 
							<Checkbox
								disabled={isSuitabilityDone}
								onChange={(e:any) => onQuestionAnswered('trainingUniversity')(e.target.checked)}
								checked={testResponses.trainingUniversity ?? false}>{translations.suitability_q1_02}
							</Checkbox>
							<Checkbox
								disabled={isSuitabilityDone}
								onChange={(e:any) => onQuestionAnswered('trainingCertification')(e.target.checked)}
								checked={testResponses.trainingCertification ?? false}>{translations.suitability_q1_03}
							</Checkbox>
							<Checkbox
								disabled={isSuitabilityDone}
								onChange={(e:any) => onQuestionAnswered('trainingCourses')(e.target.checked)}
								checked={testResponses.trainingCourses ?? false}>{translations.suitability_q1_04}
							</Checkbox>
							<Checkbox
								disabled={isSuitabilityDone}
								onChange={(e:any) => onQuestionAnswered('trainingNone')(e.target.checked)}
								checked={testResponses.trainingNone ?? false}>{translations.suitability_q1_05}
							</Checkbox>
						</Col>
						<Col span="24" className="titles">
							<strong>{translations.suitability_q2}</strong>
							<span className={`error ${errors.positionInFinancial ? 'show' : null}`}>{translations.completeCampo}</span>
							<Radio.Group
								onChange={(e:RadioChangeEvent) => onQuestionAnswered('positionInFinancial')(e.target.value)}
								disabled={isSuitabilityDone}
								value={testResponses.positionInFinancial}>
								<Space direction="vertical">
									<Radio value={1}>{translations.suitability_q2_01}</Radio>
									<Radio value={2}>{translations.suitability_q2_02}</Radio>
									<Radio value={3}>{translations.suitability_q2_03}</Radio>
								</Space>
							</Radio.Group>
						</Col>
						<Col span="24" className="titles">
							<strong>{translations.suitability_q3}</strong>
							<span className={`error ${errors.profitabilityAndRisk ? 'show' : null}`}>{translations.completeCampo}</span>
							<Radio.Group onChange={(e:RadioChangeEvent) => onQuestionAnswered('profitabilityAndRisk')(e.target.value)} disabled={isSuitabilityDone} value={testResponses.profitabilityAndRisk}> 
								<Space direction="vertical">
									<Radio value={1}>{translations.true}</Radio>
									<Radio value={2}>{translations.false}</Radio>
									<Radio value={3}>{translations.dontKnowAnswer}</Radio>
								</Space>
							</Radio.Group>
						</Col>
						<Col span="24" className="titles">
							<strong>{translations.suitability_q4}</strong>
							<span className={`error ${errors.liquidityRisk ? 'show' : null}`}>{translations.completeCampo}</span>
							<Radio.Group onChange={(e:RadioChangeEvent) => onQuestionAnswered('liquidityRisk')(e.target.value)} disabled={isSuitabilityDone} value={testResponses.liquidityRisk}>
								<Space direction="vertical">
									<Radio value={1}>{translations.dontKnowAnswer}</Radio>
									<Radio value={2}>{translations.suitability_q4_02}</Radio>
									<Radio value={3}>{translations.suitability_q4_03}</Radio>
									<Radio value={4}>{translations.suitability_q4_04}</Radio>
								</Space>
							</Radio.Group>
						</Col>
						<Col span="24" className="titles">
							<strong>{translations.suitability_q5}</strong>
							<span className={`error ${errors.marketRisk ? 'show' : null}`}>{translations.completeCampo}</span>
							<Radio.Group onChange={(e:RadioChangeEvent) => onQuestionAnswered('marketRisk')(e.target.value)} disabled={isSuitabilityDone} value={testResponses.marketRisk}>
								<Space direction="vertical">
									<Radio value={1}>{translations.dontKnowAnswer}</Radio>
									<Radio value={2}>{translations.suitability_q5_02}</Radio>
									<Radio value={3}>{translations.suitability_q5_03}</Radio>
									<Radio value={4}>{translations.suitability_q5_04}</Radio>
								</Space>
							</Radio.Group>
						</Col>
						<Col span="24" className="titles">
							<strong>{translations.suitability_q6}</strong>
							<span className={`error ${errors.ventureCapitalGoal ? 'show' : null}`}>{translations.completeCampo}</span>
							<Radio.Group onChange={(e:RadioChangeEvent) => onQuestionAnswered('ventureCapitalGoal')(e.target.value)} disabled={isSuitabilityDone} value={testResponses.ventureCapitalGoal}>
								<Space direction="vertical">
									<Radio value={1}>{translations.true}</Radio>
									<Radio value={2}>{translations.false}</Radio>
									<Radio value={3}>{translations.dontKnowAnswer}</Radio>
								</Space>
							</Radio.Group>
						</Col>
						<Col span="24" className="titles">
							<strong>{translations.suitability_q7}</strong>
							<span className={`error ${errors.ventureCapitalFirms ? 'show' : null}`}>{translations.completeCampo}</span>
							<Radio.Group onChange={(e:RadioChangeEvent) => onQuestionAnswered('ventureCapitalFirms')(e.target.value)} disabled={isSuitabilityDone} value={testResponses.ventureCapitalFirms}>
								<Space direction="vertical">
									<Radio value={1}>{translations.true}</Radio>
									<Radio value={2}>{translations.false}</Radio>
									<Radio value={3}>{translations.dontKnowAnswer}</Radio>
								</Space>
							</Radio.Group>
						</Col>
						<Col span="24" className="titles">
							<strong>{translations.suitability_q8}</strong>
							<Table
								className="table"
								pagination={false}
								dataSource={[
									{
										key: '0',
										tool: translations.suitability_q8_01,
										ops: 'bankDepositsOps',
										vol: 'bankDepositsVol',
										questionNumber: '1'
									},
									{
										key: '1',
										tool: translations.suitability_q8_02,
										ops: 'fixedIncomeOps',
										vol: 'fixedIncomeVol',
										questionNumber: '2'
									},
									{
										key: '2',
										tool: translations.suitability_q8_03,
										ops: 'variableIncomeOps',
										vol: 'variableIncomeVol',
										questionNumber: '3'
									},
									{
										key: '3',
										tool: translations.suitability_q8_04,
										ops: 'otcOps',
										vol: 'otcVol',
										questionNumber: '4'
									},
									{
										key: '4',
										tool: translations.suitability_q8_05,
										ops: 'iiC_ETFOps',
										vol: 'iiC_ETFVol',
										questionNumber: '5'
									},
									{
										key: '5',
										tool: translations.suitability_q8_06,
										ops: 'altInvestmentVehiclesOps',
										vol: 'altInvestmentVehiclesVol',
										questionNumber: '6'
									},
									{
										key: '6',
										tool: translations.suitability_q8_07,
										ops: 'capitalCallsOps',
										vol: 'capitalCallsVol',
										questionNumber: '7'
									}
								]}
							>
								<Column title="Instrumento" dataIndex="tool" key="tool"/>
								<ColumnGroup title={translations.volumeOp}>
									<Column title={translations.qOps} dataIndex="1" key="01" render={(_: any, record: Record8) => (
										<Select
											style={{width: '100%'}}
											onChange={(e) => onQuestionAnswered(record.ops)(e)}
											placeholder="Select one option"
											value={testResponses[record.ops]} 
											disabled={isSuitabilityDone} status={errors[record.ops] ? 'error' : ''}
										>
											<Option key="01" value={1}>{translations.suitability_q8_lessThan2}</Option>
											<Option key="02" value={2}>{translations.suitability_q8_moreThan2}</Option>
											<Option key="03" value={3}>{translations.suitability_q8_none}</Option>
										</Select>
									)}

										/>
									<Column title={translations.vOps} dataIndex="2" key="02"  render={(_: any, record: Record8) => (
										<Select
											style={{width: '100%'}}
											onChange={(e) => onQuestionAnswered(record.vol)(e)}
											placeholder="Select one option"
											value={testResponses[record.vol]} 
											disabled={isSuitabilityDone} status={errors[record.vol] ? 'error' : ''}
										>
											<Option key="04" value={1}>{translations.suitability_q8_lessThan25k}</Option>
											<Option key="05" value={2}>{translations.suitability_q8_lessThan150k}</Option>
											<Option key="08" value={5}>{translations.suitability_q8_lessThan500k}</Option>
											<Option key="06" value={3}>{translations.suitability_q8_moreThan500k}</Option>
											<Option key="07" value={4}>{translations.suitability_q8_none}</Option>
										</Select>
									)}/>
								</ColumnGroup>
							</Table>
						</Col>
						<Col span="24" className="titles">
							<strong>{translations.suitability_q9}</strong>
							<span className={`error ${errors.investmentTime ? 'show' : null}`}>{translations.completeCampo}</span>
							<Radio.Group onChange={(e:RadioChangeEvent) => onQuestionAnswered('investmentTime')(e.target.value)} disabled={isSuitabilityDone} value={testResponses.investmentTime}>
								<Space direction="vertical">
									<Radio value={1}>{translations.suitability_q9_01}</Radio>
									<Radio value={2}>{translations.suitability_q9_02}</Radio>
									<Radio value={3}>{translations.suitability_q9_03}</Radio>
									<Radio value={4}>{translations.suitability_q9_04}</Radio>
								</Space>
							</Radio.Group>
						</Col>
					</Row>
				</Col>
			</FormLayout>
			<Modal
				className="not-suitable-confirmation-modal"
				width={600}
				open={isOpenConfirmModal}
				closeIcon={null}
				centered
				footer={[
					<Row justify="space-between">
						<Col>
							<Button onClick={onCancelContinue}>{translations.cancel}</Button>
						</Col>
						<Col>
							<Button type="primary" onClick={toNextStep}>{translations.continue}</Button>
						</Col>
					</Row>
				]}
			>
				<Row className="not-suitable-text" justify="center" align="middle">
					<h1><WarningFilled/></h1>
					<h2>{translations.suitabilityResult}</h2>
				</Row>
			</Modal>
		</Row>
	);
};

export default SuitabilityTest;