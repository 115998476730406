import AxiosAPI from '@qualitas/libs';
import { AxiosResponse } from 'axios';

import { Beneficiaries } from '../Models/Beneficiaries';

export const BeneficiaryAPI = (baseURL: string | undefined, getAuthToken: () => string) => {
    const api = AxiosAPI({
        baseURL,
        getAuthToken,
        isApp: true,
        hasUnderMaintenance: false
    });

    const getBeneficiaries = (ContractId: number) => {
        const serviceUrl = `/bookbuilding-api/Beneficiary/GetBeneficiaries`;
        return api.get(serviceUrl, { params: { ContractId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const saveBeneficiaries = (data: Beneficiaries[]) => {
        const serviceUrl = `/bookbuilding-api/Beneficiary/SaveBeneficiaries`;
        return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

    const updateBeneficiaries = (data: Beneficiaries[]) => {
        const serviceUrl = `/bookbuilding-api/Beneficiary/UpdateBeneficiaries`;
        return api.put(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };

	const deleteBeneficiary = (id: number) => {
        const serviceUrl = `/bookbuilding-api/Beneficiary/DeleteBeneficiary`;
        return api.delete(serviceUrl, { params: { id } })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => error);
    };


    return {
        getBeneficiaries,
        saveBeneficiaries,
		updateBeneficiaries,
		deleteBeneficiary
    };
};
